<template>
    <section id ='filledRecently'>
            <div class='narrowBox'>
            <h1>תודה שחזרת אלינו!</h1>
            <div class='msg'>
                נראה שכבר מילאת את השאלון מתישהו בשעתיים האחרונות.
                מה {{ $parse('תרצה', 'תרצי') }}
                לעשות?
                <!-- <div class='buttonContainer'> -->
                    <button study-button type='button' @click='goToQuesitonnaire'>להמשיך אל השאלון</button>
                    <button study-button type='button' @click='$router.push("final")'>לצפות שוב במסך הסיום</button>
                    <button study-button type='button' @click='$router.push("/")'>לצאת מכאן</button>
                <!-- </div> -->
            </div>
      </div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import chatModule from '@/Chat/module';

export default defineComponent({
    mounted(){
        if(!this.$store.hasModule('chat'))
            this.$store.registerModule('chat', chatModule);
    },
    methods: {
        goToQuestionnaire(){
            import(`@/${this.appMode}/helpers`).then(({ goToQuestionnaire }) => goToQuestionnaire())
        }
    }
});
</script>

<style lang="scss">
#filledRecently {
    margin: 3rem auto;

    & .msg {
        padding: 1rem;
    }

    & button {
        display: block;
        width: 90%;
        margin: 1rem auto;

        &:first-of-type {
            margin-top: 2rem;
        }
    }
    & .buttonContainer {
        width: 100%;
        padding: 1rem 1rem 0;
        flex-flow: column;

        & button {
            margin: 0.5rem 0;
        }
    }
}
</style>
