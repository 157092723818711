
import { defineComponent } from '@vue/runtime-core';
import chatModule from '@/Chat/module';

export default defineComponent({
    mounted(){
        if(!this.$store.hasModule('chat'))
            this.$store.registerModule('chat', chatModule);
    },
    methods: {
        goToQuestionnaire(){
            import(`@/${this.appMode}/helpers`).then(({ goToQuestionnaire }) => goToQuestionnaire())
        }
    }
});
